export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'sales',
      displayName: 'menu.sales',
      meta: {
        icon: 'paid',
      },
      children: [
        {
          name: 'orders',
          displayName: 'menu.orders',
        },
        {
          name: 'kitchen',
          displayName: 'menu.kitchen',
        },
      ],
    },
    {
      name: 'reports',
      displayName: 'menu.report',
      meta: {
        icon: 'account_balance_wallet',
      },
      children: [
        {
          name: 'revenue-report-by-day',
          displayName: 'menu.revenue-report-by-day',
        },
      ],
    },
    {
      name: 'category-management',
      displayName: 'menu.category-management',
      meta: {
        icon: 'list_alt',
      },
      children: [
        {
          name: 'areas',
          displayName: 'menu.areas',
        },
        {
          name: 'tables',
          displayName: 'menu.tables',
        },
        {
          name: 'categories',
          displayName: 'menu.category',
        },
        {
          name: 'foods',
          displayName: 'menu.food',
        },
        {
          name: 'units',
          displayName: 'menu.unit',
        },
      ],
    },
    {
      name: 'accounts-management',
      displayName: 'menu.accounts-management',
      meta: {
        icon: 'group',
      },
      children: [
        {
          name: 'users',
          displayName: 'menu.users',
        },
        {
          name: 'roles',
          displayName: 'menu.roles',
        },
      ],
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
    // {
    //   name: 'payments',
    //   displayName: 'menu.payments',
    //   meta: {
    //     icon: 'credit_card',
    //   },
    //   children: [
    //     {
    //       name: 'payment-methods',
    //       displayName: 'menu.payment-methods',
    //     },
    //     {
    //       name: 'pricing-plans',
    //       displayName: 'menu.pricing-plans',
    //     },
    //     {
    //       name: 'billing',
    //       displayName: 'menu.billing',
    //     },
    //   ],
    // },
    // {
    //   name: 'preferences',
    //   displayName: 'menu.preferences',
    //   meta: {
    //     icon: 'manage_accounts',
    //   },
    // },
  ] as INavigationRoute[],
}
