import repository from './repository'

export default {
  patch(endpoint: string): Promise<any> {
    return repository.patch(endpoint)
  },
  patchWithData(endpoint: string, data: any): Promise<any> {
    return repository.patch(endpoint, data)
  },
}
