export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || 'Please enter a valid email address'
  },
  required: (v: any) => !!v || 'This field is required',
  minValue: (min: number, msg: string) => (v: any) => v >= min || `${msg}`,
}

export const formatVietnamCurrency = (value: number | undefined) => {
  if (value === null || value === undefined) return ''
  return new Intl.NumberFormat('en-US').format(value)
}

export const calcBillSummary = (orders: any[] | undefined) => {
  if (orders === null || orders === undefined) return null
  return {
    money:
      orders.reduce((sum: number, order: any) => {
        return sum + order.foodCount * order.foodPrice
      }, 0) || 0,
    total:
      orders.reduce((sum: number, order: any) => {
        return sum + order.foodCount * order.foodPrice
      }, 0) || 0,
  }
}

export const getTimeNow = () => {
  const today = new Date()
  const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
  return date + ' ' + time
}

export const paidMethod = {
  Cash: 'cash', // Tiền mặt
  CreditCard: 'credit_card', // Thẻ tín dụng
  MobilePayment: 'mobile_payment', // Thanh toán qua internet banking
}

// convert string time with timezone and format to string time with format
export const convertTimeAndFormat = (dateTime: string, timezone: string) => {
  const dateTimeAddTimezone = new Date(dateTime).toLocaleString('en-US', { timeZone: timezone })
  const dateTimeConv = new Date(dateTimeAddTimezone)
  const date = dateTimeConv.getDate() + '/' + (dateTimeConv.getMonth() + 1) + '/' + dateTimeConv.getFullYear()
  const time = dateTimeConv.getHours() + ':' + dateTimeConv.getMinutes() + ':' + dateTimeConv.getSeconds()
  return date + ' ' + time
}
