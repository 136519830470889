<template>
  <VaLayout
    :top="{ fixed: true, order: 3 }"
    :left="{
      fixed: true,
      absolute: breakpoints.smDown,
      order: 2,
      overlay: breakpoints.smDown && isLeftSidebarVisible,
    }"
    :right="{
      fixed: true,
      absolute: breakpoints.smDown,
      order: 2,
      overlay: breakpoints.smDown && isRightSidebarVisible,
    }"
    :bottom="{ fixed: true, order: 4 }"
    @leftOverlayClick="isLeftSidebarVisible = false"
    @rightOverlayClick="isRightSidebarVisible = false"
  >
    <template #top>
      <!-- <VaNavbar shadowed>
        <template #left>
          <VaButton
            preset="secondary"
            :icon="isLeftSidebarVisible ? 'menu_open' : 'menu'"
            @click="isLeftSidebarVisible = !isLeftSidebarVisible"
          />
        </template>
        <template #right>
          <div style="display: flex; align-items: center">
            <VaButton
              preset="secondary"
              :icon="isRightSidebarVisible ? 'menu_open' : 'menu'"
              @click="isRightSidebarVisible = !isRightSidebarVisible"
            />
          </div>
        </template>
      </VaNavbar> -->
      <AppNavbar :is-mobile="false" />
    </template>

    <!-- <template #left>
      <VaSidebar v-model="isLeftSidebarVisible">
        <VaSidebarItem v-for="{ icon, title } in menu" :key="icon">
          <VaSidebarItemContent>
            <VaIcon :name="icon" />
            <VaSidebarItemTitle>
              {{ title }}
            </VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>
      </VaSidebar>
    </template>

    <template #right>
      <VaSidebar v-model="isRightSidebarVisible">
        <VaSidebarItem v-for="{ icon, title } in subMenu" :key="icon">
          <VaSidebarItemContent>
            <VaIcon :name="icon" />
            <VaSidebarItemTitle>
              {{ title }}
            </VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>
      </VaSidebar>
    </template> -->

    <template #content>
      <main class="p-2">
        <RouterView />
      </main>
    </template>

    <template #bottom>
      <SaleBottomNavbar></SaleBottomNavbar>
    </template>
  </VaLayout>
  <ResetPasswordModal v-if="isResetPasswordModalOpen" @cancel="isResetPasswordModalOpen = false" />
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'
import { useBreakpoint } from 'vuestic-ui'
import { onBeforeRouteUpdate } from 'vue-router'
import { useGlobalStore } from '../stores/global-store'
import { storeToRefs } from 'pinia'

import AppNavbar from '../components/sales/navbar/AppNavbar.vue'
import SaleBottomNavbar from '../components/sales/SaleBottomNavbar.vue'
import ResetPasswordModal from './../pages/preferences/modals/ResetPasswordModal.vue'

const GlobalStore = useGlobalStore()

const breakpoints = useBreakpoint()

const isLeftSidebarVisible = ref(breakpoints.smUp)
const isRightSidebarVisible = ref(breakpoints.smUp)
const isResetPasswordModalOpen = ref(false)

const isMobile = ref(false)
const isTablet = ref(false)
const { isSidebarMinimized } = storeToRefs(GlobalStore)

const onResize = () => {
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

watchEffect(() => {
  isLeftSidebarVisible.value = breakpoints.smUp
  isRightSidebarVisible.value = breakpoints.smUp
})
</script>
