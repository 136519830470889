{
  "auth": {
    "agree": "tôi đồng ý với",
    "createAccount": "Tạo tài khoản",
    "createNewAccount": "Tạo tài khoản mới",
    "email": "Email",
    "login": "Đăng nhập",
    "username": "Tên đăng nhập",
    "password": "Mật khẩu",
    "recover_password": "Recover password",
    "forgot_password": "Quên mật khẩu",
    "sign_up": "Sign Up",
    "keep_logged_in": "Giữ đăng nhập trên thiết bị này",
    "termsOfUse": "Terms of Use.",
    "reset_password": "Reset password",
    "login-msg-success": "Bạn đã đăng nhập thành công",
    "login-msg-failed": "Đăng nhập thất bại"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Quay lại trang chủ"
  },
  "typography": {
    "primary": "Primary text styles",
    "secondary": "Secondary text styles"
  },
  "dashboard": {
    "versions": "Versions",
    "setupRemoteConnections": "Setup Remote Connections",
    "currentVisitors": "Current Visitors",
    "navigationLayout": "navigation layout",
    "topBarButton": "Top Bar",
    "sideBarButton": "Side Bar"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "English",
    "spanish": "Spanish",
    "simplified_chinese": "Simplified Chinese",
    "persian": "Persian"
  },
  "menu": {
    "admin": "Quản trị",
    "auth": "Auth",
    "buttons": "Buttons",
    "timelines": "Timelines",
    "dashboard": "Dashboard",
    "billing": "Billing",
    "login": "Đăng nhập",
    "preferences": "Tài khoản cá nhân",
    "payments": "Payments",
    "settings": "Cài đặt hệ thống",
    "pricing-plans": "Pricing plans",
    "payment-methods": "Payment methods",
    "signup": "Signup",
    "recover-password": "Recover password",
    "recover-password-email": "Recover password email",
    "404": "404",
    "faq": "FAQ",
    "users": "Tài khoản",
    "projects": "Projects",
    "sales": "Bán hàng",
    "orders": "Đặt món",
    "kitchen": "Bếp",
    "items": "Danh mục",
    "category-management": "Danh mục",
    "areas": "Khu vực",
    "tables": "Bàn",
    "category": "Nhóm món ăn",
    "food": "Món ăn",
    "unit": "Đơn vị tính",
    "accounts-management": "Tài khoản",
    "roles": "Phân quyền",
    "report": "Báo cáo",
    "revenue-report": "Doanh thu",
    "revenue-report-by-day": "Doanh thu theo ngày",
    "revenue-report-by-bill": "Doanh thu theo hoá đơn"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read"
  },
  "navbar": {
    "messageUs": "Web development inquiries:",
    "repository": "GitHub Repo"
  },
  "notifications": {
    "all": "Xem tất cả thông báo",
    "less": "Thu gọn thông báo",
    "mark_as_read": "Đã đọc",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "user": {
    "language": "Change language",
    "logout": "Đăng xuất",
    "profile": "Profile",
    "changePassword": "Thay đổi mật khẩu",
    "settings": "Settings",
    "billing": "Billing",
    "faq": "FAQ",
    "helpAndSupport": "Help & support",
    "projects": "Projects",
    "account": "Account",
    "admin": "Quản trị",
    "explore": "Explore"
  },
  "area": {
    "title": "Khu vực",
    "newFormTitle": "Thêm khu vực",
    "editFormTitle": "Sửa khu vực",
    "button": {
      "add": "Thêm khu vực",
      "edit": "Sửa khu vực",
      "delete": "Xóa",
      "save": "Lưu"
    },
    "areaName": "Tên khu vực"
  },
  "dinnerTable": {
    "title": "Bàn/Phòng",
    "newFormTitle": "Thêm Bàn",
    "editFormTitle": "Sửa Bàn",
    "button": {
      "add": "Thêm Bàn",
      "edit": "Sửa Bàn",
      "delete": "Xóa",
      "save": "Lưu"
    },
    "tableName": "Tên Bàn"
  },
  "unit": {
    "title": "Đơn vị tính",
    "newFormTitle": "Thêm Đơn vị tính",
    "editFormTitle": "Sửa đơn vị tính",
    "button": {
      "add": "Thêm đơn vị tính",
      "edit": "Sửa đơn vị tính",
      "delete": "Xóa",
      "save": "Lưu"
    },
    "unitName": "Tên Đơn vị tính"
  },
  "category": {
    "title": "Nhóm món ăn",
    "newFormTitle": "Thêm nhóm món ăn",
    "editFormTitle": "Sửa nhóm món ăn",
    "button": {
      "add": "Thêm nhóm món ăn",
      "edit": "Sửa nhóm món ăn",
      "delete": "Xóa",
      "save": "Lưu"
    },
    "categoryName": "Tên nhóm món ăn"
  },
  "food": {
    "title": "Món ăn",
    "newFormTitle": "Thêm Món ăn",
    "editFormTitle": "Sửa Món ăn",
    "button": {
      "add": "Thêm Món ăn",
      "edit": "Sửa Món ăn",
      "delete": "Xóa",
      "save": "Lưu"
    },
    "foodCode": "Mã Món ăn",
    "foodName": "Tên Món ăn",
    "foodCategory": "Nhóm Món ăn",
    "foodUnit": "ĐVT",
    "foodPrice": "Giá",
    "foodImage": "Hình ảnh"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Send"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs alignment",
    "overflow": "Tabs overflow",
    "hidden": "Tabs with hidden slider",
    "grow": "Tabs grow"
  },
  "helpAndSupport": "Hỗ trợ",
  "aboutVuesticAdmin": "About Vuestic Admin",
  "search": {
    "placeholder": "Search..."
  },
  "buttonSelect": {
    "dark": "Dark",
    "light": "Light"
  },
  "common": {
    "paging": {
      "totalResults": "dòng",
      "resultsPerPage": "Số dòng trên trang"
    },
    "button": {
      "save": "Lưu",
      "add": "Thêm",
      "cancel": "Huỷ",
      "delete": "Xoá"
    },
    "input": {
      "search": "Tìm kiếm",
      "notes": "Ghi chú"
    }
  },
  "modalForm": {
    "title": "Modal",
    "message": {
      "areYouSureWantToActObj": "Bạn có chắc chắn muốn {act} {obj}?"
    },
    "button": {
      "open": "Open Modal",
      "close": "Close Modal"
    }
  }
}
