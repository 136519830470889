<template>
  <div class="flex flex-col justify-center content-center">
    <VaHover stateful>
      <template #default="{ hover }">
        <VaButton
          class="flex"
          preset="secondary"
          hover-behavior="opacity"
          :hover-opacity="0.4"
          pressed-behavior="mask"
          :pressed-opacity="1"
          pressed-mask-color="primary"
          title="Chọn Bàn"
          :color="hover ? 'secondary' : 'primary'"
          @click="showTablesModal"
        >
          <VaIcon name="library_add" color="primary" size="large" />
        </VaButton>
      </template>
    </VaHover>
  </div>
  <div class="billTabs">
    <VaTabs v-model="saleTableActive" left class="flex" :class="billTabsWidthChange">
      <template #tabs>
        <VaTab
          v-for="tb in allTables"
          :key="tb.id"
          :name="tb.id"
          :aria-selected="tb.id === saleTableActive"
          color="primary"
          class="flex billTab"
        >
          <div class="flex">
            <div class="mr-6 justify-center content-center">{{ tb.tableName }}</div>
            <div class="">
              <VaMenu :options="options" @selected="(v: any) => invoiceActions(tb.id, v)">
                <template #anchor>
                  <VaButton
                    preset="secondary"
                    hover-behavior="opacity"
                    :hover-opacity="0.4"
                    icon="arrow_drop_down"
                    round
                    class="m-0 p-0 min-w-6 min-h-6"
                  />
                </template>
              </VaMenu>
            </div>
          </div>
        </VaTab>
      </template>
    </VaTabs>
  </div>
  <TablesForSale ref="choiceModalRef"></TablesForSale>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useSalesStore } from '../../../../stores/sales'
import { useModal } from 'vuestic-ui'
import TablesForSale from './TablesForSale.vue'
import { calcBillSummary, paidMethod } from '../../../../services/utils'

const { confirm } = useModal()
const options = [
  // { text: 'Đổi Bàn', value: 'change_table', icon: 'sync_alt' },
  // { text: 'Ghép Bàn', value: 'combine_table', icon: 'dataset' },
  { text: 'Lưu hoá đơn', value: 'save_invoice', icon: 'done_all' },
  { text: 'Xoá hoá đơn', value: 'del_invoice', icon: 'delete_forever' },
]
const store = useSalesStore()
const saleTableActive = computed({
  get() {
    return store.activeOrderID
  },
  set(value) {
    store.activeOrderID = value
  },
})
const allTables = computed(() => store.saleTables)
const billTabsWidthChange = computed(() => {
  let maxW = 230
  maxW = maxW * (store.saleTables.length ? store.saleTables.length : 1)
  if (maxW > 800) {
    maxW = 800
  }
  return `w-[${maxW}px] max-w-[${maxW}px]`
})

const deleteInvoice = (id: string): void => {
  confirm({
    title: `[${store.getSaleTable(id)?.tableName}] bạn muốn xóa hoá đơn cho bàn này?`,
    message: 'Đảm bảo hoá đơn này đã được thanh toán.',
    okText: 'Đồng ý',
    cancelText: 'Huỷ',
    size: 'small',
  }).then((ok) => ok && store.removeOrder(id))
}

const savePaidInvoice = (id: string): void => {
  confirm({
    title: `[${store.getSaleTable(id)?.tableName}] bạn muốn lưu hoá đơn cho bàn này?`,
    message: 'Đảm bảo hoá đơn này đã được thanh toán.',
    okText: 'Đồng ý',
    cancelText: 'Huỷ',
    size: 'small',
  }).then((ok) => {
    if (ok) {
      const saleTable = store.getSaleTable(id)
      if (saleTable) {
        const orders = saleTable.orders || []
        const total = orders ? calcBillSummary(orders)?.total : 0
        if (typeof total !== 'undefined') {
          store.paidInvoice(id, total, paidMethod.Cash, total)
        }
      }
    }
  })
}

const choiceModalRef = ref()
const showTablesModal = () => {
  choiceModalRef.value.doShowModal = true
}

const invoiceActions = (id: string, v: any) => {
  if (!v) return
  if (!v.value) return
  if (v.value === 'del_invoice') {
    deleteInvoice(id)
    return
  }
  if (v.value === 'save_invoice') {
    savePaidInvoice(id)
    return
  }
}
</script>

<style lang="scss">
.billTabs {
  flex: 0 1 auto;
  width: 100%;
  overflow: hidden;
  height: 48px;

  .va-tabs {
    &__container {
      height: 48px !important;
    }
  }

  .billTab {
    height: 48px;
    margin-right: 2px;

    .table_logo {
      visibility: hidden;
    }

    &last-child {
      margin-right: 0;
    }
  }

  .billTab:hover,
  .billTab[aria-selected='true'] {
    cursor: pointer;
    border-top-right-radius: var(--va-card-border-radius);
    border-top-left-radius: var(--va-card-border-radius);
    background-color: rgb(2 132 199) !important;
    color: white !important;
    .table_logo {
      visibility: visible;
    }
  }

  .billTab:hover i.va-icon,
  .billTab[aria-selected='true'] i.va-icon {
    color: white !important;
  }

  .billTab:hover i.va-icon,
  .billTab[aria-selected='true'] i.va-icon {
    color: white !important;
  }
}
</style>
