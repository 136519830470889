import axios from 'axios'

//const baseDomain = 'http://localhost:1323'
const baseDomain = 'https://hsq-api.lifedowns.com'
const baseURL = `${baseDomain}/v1`

const makeHeaders = () => {
  const headers: { [key: string]: string } = {
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': 'vi',
  }
  const token = localStorage.getItem('authToken')
  if (token) {
    const auth = `Bearer ${token}`
    headers['Authorization'] = auth
  }

  return headers
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  withXSRFToken: true,
  xsrfHeaderName: 'X-Hsq',
  xsrfCookieName: 'sails.hsqid',
  headers: makeHeaders(),
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    const crsf = localStorage.getItem('myCRSF')
    if (crsf) {
      config.headers['X-Hsq'] = crsf
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers['New-Token']) {
      console.log(response.headers['New-Token'])
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
export default axiosInstance
