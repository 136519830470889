<template>
  <VaModal
    v-model="doShowModal"
    close-button
    no-outside-dismiss
    fixed-layout
    hide-default-actions
    max-height="100%"
    class="tables-for-sale"
  >
    <template #header>
      <div class="flex basis-full font-bold uppercase">Danh sách bàn</div>
      <VaDivider class="basis-full" dashed />
    </template>
    <VaScrollContainer class="mt-3 tables-content" vertical>
      <div v-for="area in areas" :key="area.areaID">
        <div class="flex font-bold">{{ area.areaName }}</div>
        <div class="flex">
          <div
            v-for="table in listTable(area.areaID)"
            :key="table.tableID"
            class="basis-36 h-20 m-2 px-3 justify-center content-center table-name"
            :aria-disabled="checkTableSaled(table.tableID)"
            @click="selectTable(table)"
          >
            <div class="basis-11/12">
              <VaIcon
                class="mr-1"
                name="table_restaurant"
                :color="checkTableSaled(table.tableID) ? 'secondary' : 'primary'"
                size="large"
              />
              {{ table.tableName }}
            </div>
          </div>
        </div>
      </div>
    </VaScrollContainer>
  </VaModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useSalesStore } from '../../../../stores/sales'
import { DinnerTable } from '../../../../pages/admin/tables/types'
import { SaleTable } from '../../../../pages/sales/types'

const store = useSalesStore()
const doShowModal = ref(false)

const areas = computed(() => store.areas)
const listTable = (areaID: string): DinnerTable[] => {
  return store.settingTables.filter((table: any) => table.areaID === areaID)
}
const checkTableSaled = (tableID: string) => {
  if (store.saleTables.length > 0) {
    if (store.saleTables.findIndex((table: SaleTable) => table.tableID === tableID) > -1) {
      return true
    }
  }
  return false
}

defineExpose({
  doShowModal,
})

const selectTable = (table: DinnerTable) => {
  if (checkTableSaled(table.tableID)) {
    return
  }
  store.addSaleTable(table.tableID, 1)
  doShowModal.value = false
}
</script>

<style lang="scss">
.tables-for-sale {
  .va-modal {
    &__dialog {
      margin: 0;
      width: 80%;
      height: 100%;
      background-color: var(--va-background-secondary);
      padding: 10px;
    }
    &__message {
      .tables-content {
        height: calc(100vh - 80px);
        max-height: calc(100vh - 80px);

        .table-name {
          cursor: pointer;
          border-radius: 4px;
          padding: 5px;
          background-color: #f5f5f5;
          border: 1px solid transparent;
          &:hover {
            background-color: #e5e5e5;
            border: 1px solid var(--va-primary);
          }
        }

        .table-name[aria-disabled='true']:hover {
          cursor: not-allowed;
          border: 1px solid transparent;
        }
      }
    }
  }
}
</style>
