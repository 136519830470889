import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import ChildComponent from '../layouts/AppChildLayout.vue'
import RouteViewComponent from '../layouts/RouterBypass.vue'
import SalesLayout from '../layouts/SalesLayout.vue'
import { useUserStore } from '../stores/user-store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'pageNotFound' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'accounts-management',
        path: '/accounts-management',
        component: ChildComponent,
        children: [
          {
            name: 'users',
            path: 'users',
            component: () => import('../pages/users/UsersPage.vue'),
          },
          {
            name: 'roles',
            path: 'roles',
            component: () => import('../pages/roles/UsersPage.vue'),
          },
        ],
      },
      {
        name: 'category-management',
        path: '/category-management',
        component: ChildComponent,
        children: [
          {
            name: 'areas',
            path: 'areas',
            component: () => import('../pages/admin/areas/AreasPage.vue'),
          },
          {
            name: 'tables',
            path: 'tables',
            component: () => import('../pages/admin/tables/DinnerTablesPage.vue'),
          },
          {
            name: 'categories',
            path: 'categories',
            component: () => import('../pages/admin/categories/CategoriesPage.vue'),
          },
          {
            name: 'foods',
            path: 'foods',
            component: () => import('../pages/admin/food/FoodPage.vue'),
          },
          {
            name: 'units',
            path: 'units',
            component: () => import('../pages/admin/units/UnitsPage.vue'),
          },
        ],
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'reports',
        path: '/reports',
        component: ChildComponent,
        children: [
          {
            name: 'revenue-report-by-day',
            path: 'revenue-report-by-day',
            component: () => import('../pages/admin/reports/revenue/by-date/RevenueByDatePage.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
  },
  {
    name: 'sales',
    path: '/sales',
    component: SalesLayout,
    children: [
      {
        name: 'orders',
        path: 'orders',
        component: () => import('../pages/sales/OrdersPage.vue'),
      },
      {
        name: 'kitchen',
        path: 'kitchen',
        component: () => import('../pages/sales/KitchenPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'logout',
        path: 'logout',
        component: () => import('../pages/auth/Logout.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    name: 'pageNotFound',
    path: '/pageNotFound',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const store = useUserStore()
  const publicPages = ['/auth/login', '/404', '/pageNotFound']
  const authRequired = !publicPages.includes(to.path)
  //const userCanAccess = await canUserAccess()

  if (authRequired && !store.isAuthenticated()) next('/auth/login')
  else next()
})

// const canUserAccess = async () => {
//   const role = 'admin'
//   const store = useUserStore()
//   const roles = store.userRoles

//   if (roles && !roles.includes(role)) return false
//   else return true
// }

export default router
