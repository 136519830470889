import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    const loggedData = localStorage.getItem('userInfo')
    const userInfo = loggedData ? JSON.parse(loggedData) : null
    if (!userInfo) {
      return {
        userInfo: null,
        userSettings: null,
        userRoles: [],
        userName: '',
        timezone: 'Asia/Ho_Chi_Minh',
        email: '',
        memberSince: '',
        pfp: '',
        is2FAEnabled: false,
      }
    }

    return {
      userInfo: userInfo.info,
      userSettings: userInfo.settings,
      userRoles: ['admin'],
      userName: userInfo.info.fullname,
      email: userInfo.info.email,
      memberSince: '8/12/2020',
      pfp: 'https://picsum.photos/id/22/200/300',
      is2FAEnabled: false,
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },
    changeUserName(userName: string) {
      this.userName = userName
    },
    getUserRoles() {
      return ['admin']
    },
    isAuthenticated() {
      return this.userInfo !== null
    },
    setUserInfo() {
      const userInfo = localStorage.getItem('userInfo')
      this.userInfo = userInfo ? JSON.parse(userInfo) : null
    },
    clearUserInfo() {
      this.userInfo = null
    },
  },
})
