import { SaleTable } from '../../pages/sales/types'
import { saledTables } from '../../services/http/sales/ordersService'
import { sleep } from '../../services/utils'
import { Pagination } from '../pages/common'

export const getSaledTables = async (filters: Partial<Pagination>) => {
  await sleep(1000)

  const tables = await saledTables(filters).then((response) => {
    return response
  })

  if (!tables.list) {
    return {
      data: [],
    }
  }

  let filtered = tables.list as SaleTable[]
  filtered = await Promise.all(
    filtered.map(async (tb) => {
      return {
        ...tb,
      }
    }),
  )

  return {
    data: filtered,
  }
}
