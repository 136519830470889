<template>
  <VaDivider style="margin: 0" />
  <VaCard class="p-0 m-0">
    <VaCardContent class="m-0 p-0">
      <div class="flex">
        <div class="basis-1/2">
          <VaButton class="m-0 p-2 b-bottom-navbar b-bottom-navbar-active" icon="dataset" preset="secondary">
            Bán tại quán
          </VaButton>
          <VaButton
            class="m-0 p-2 b-bottom-navbar"
            icon="electric_bolt"
            preset="secondary"
            color="info"
            @click="saleTakeAWay()"
          >
            Bán mang về
          </VaButton>
        </div>
        <div class="basis-1/2 content-center">
          <div class="flex justify-end mr-3">
            <div class="content-center text-zinc-400 mr-3">
              <div>
                <VaIcon class="material-symbols-outlined"> home_pin </VaIcon>
                Dê Hương Sơn Quán 4 - Vĩnh Cửu, Đồng Nai
              </div>
            </div>
            <div>
              <VaButton
                size="small"
                round
                preset="secondary"
                hover-behavior="opacity"
                :hover-opacity="0.4"
                href="https://lifedowns.com"
                target="_blank"
                color="textPrimary"
              >
                <VaIcon class="material-symbols-outlined"> help </VaIcon>
              </VaButton>
            </div>
          </div>
        </div>
      </div>
    </VaCardContent>
  </VaCard>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import { useSalesStore } from '../../stores/sales'

export default defineComponent({
  name: 'SaleBottomNavbar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible', 'click'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const { t } = useI18n()

    const value = ref<boolean[]>([])
    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const store = useSalesStore()
    const saleTakeAWay = () => {
      store.addSaleTakeAWay()
    }

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      t,
      arrowDirection,
      saleTakeAWay,
    }
  },
})
</script>

<style lang="scss" scoped>
.b-bottom-navbar {
  border-radius: 0 !important;
  border-top: 2px solid transparent !important;
}

.b-bottom-navbar:hover,
.b-bottom-navbar-active {
  border-top: 2px solid var(--va-primary) !important;
}
</style>
