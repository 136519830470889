import { sleep } from '../../services/utils'
import { DinnerTable } from '../../pages/admin/tables/types'
import { list, add, update, scratch } from '../../services/http/admin/tableService'
import { getID } from '../../services/http/admin/areaService'
import { Pagination } from './common'

const getTableArea = (areaID: string) => {
  return getID(areaID)
    .then((response) => {
      return response
    })
    .catch(() => {
      return {}
    })
}

// Simulate API calls
export type Sorting = {
  sortBy: keyof DinnerTable | undefined
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: boolean
  search: string
}

export const getTables = async (filters: Partial<Filters & Pagination & Sorting>) => {
  await sleep(1000)

  const tables = await list(filters).then((response) => {
    return response
  })

  if (!tables.list) {
    return {
      data: [],
      pagination: {
        page: tables.metadata.currentPage,
        size: tables.metadata.size,
        total: 0,
        totalPages: tables.metadata.totalPages,
      },
    }
  }

  let filtered = tables.list as DinnerTable[]
  filtered = await Promise.all(
    filtered.map(async (tb) => ({
      ...tb,
      area: await getTableArea(tb.areaID),
    })),
  )

  return {
    data: filtered,
    pagination: {
      page: tables.metadata.currentPage,
      size: tables.metadata.size,
      total: filtered.length * tables.metadata.totalPages,
      totalPages: tables.metadata.totalPages,
    },
  }
}

export const getAllTable = async () => {
  await sleep(1000)

  const filters = {
    page: 1,
    size: 9999,
  }
  const tables = await list(filters).then((response) => {
    return response
  })

  if (!tables.list) {
    return {
      data: [],
    }
  }

  return {
    data: tables.list as DinnerTable[],
  }
}

export const addTable = async (table: DinnerTable) => {
  await sleep(1000)

  table.areaID = table.area.areaID
  await add(table).then((response) => {
    return response
  })
}

export const updateTable = async (table: DinnerTable) => {
  await sleep(1000)

  table.areaID = table.area.areaID
  await update(table.tableID, table).then((response) => {
    return response
  })
}

export const removeTable = async (table: DinnerTable) => {
  await sleep(1000)
  await scratch(table.tableID).then((response) => {
    return response
  })
}
