import deletesRepository from '../deletesRepository'
import getRepository from '../getRepository'
import postsRepository from '../postsRepository'
import putsRepository from '../putsRepository'
import utilsRepository from '../utilsRepository'

const http = {
  gets: getRepository,
  posts: postsRepository,
  puts: putsRepository,
  deletes: deletesRepository,
  utils: utilsRepository,
}

const endpoint = '/admin/area'

export const list = async (filter: any) => {
  return await http.gets
    .getWithParams(`${endpoint}s`, filter)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const getID = async (id: string) => {
  return await http.gets
    .get(`${endpoint}/${id}`)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const add = async (data: any) => {
  return await http.posts
    .post(`${endpoint}s`, data)
    .then(() => {})
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const update = async (id: string, data: any) => {
  return await http.puts
    .put(`${endpoint}/${id}`, data)
    .then(() => {})
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const scratch = async (id: string) => {
  return await http.deletes
    .scratch(`${endpoint}/${id}`)
    .then(() => {})
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}
