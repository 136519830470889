import deletesRepository from '../deletesRepository'
import getRepository from '../getRepository'
import patchRepository from '../patchRepository'
import postsRepository from '../postsRepository'
import putsRepository from '../putsRepository'
import utilsRepository from '../utilsRepository'

const http = {
  gets: getRepository,
  posts: postsRepository,
  puts: putsRepository,
  patch: patchRepository,
  deletes: deletesRepository,
  utils: utilsRepository,
}

export const saledTables = async (filter: any) => {
  return await http.gets
    .getWithParams(`sales/saled-tables`, filter)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const getOrderService = async (filter: any) => {
  return await http.gets
    .getWithParams(`sales/orders`, filter)
    .then(() => {})
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const newOrderService = async (data: any) => {
  return await http.posts
    .post(`sales/orders`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const newSaleTakeAwayService = async (data: any) => {
  return await http.posts
    .post(`sales/orders/take-away`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const updateOrderService = async (orderID: string, data: any) => {
  return await http.puts
    .put(`sales/orders/${orderID}`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const removeOrderService = async (orderID: string) => {
  return await http.deletes
    .scratch(`sales/orders/${orderID}`)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const addOrderDetailService = async (orderID: string, data: any) => {
  return await http.posts
    .post(`sales/orders/${orderID}`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const updateOrderDetailService = async (orderID: string, orderDetailID: string, data: any) => {
  return await http.puts
    .put(`sales/orders/${orderID}/${orderDetailID}`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const deleteOrderDetailService = async (orderID: string, orderDetailID: string) => {
  return await http.deletes
    .scratch(`sales/orders/${orderID}/${orderDetailID}`)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}

export const paidInvoiceService = async (orderID: string, data: any) => {
  return await http.patch
    .patchWithData(`sales/orders/${orderID}/paid`, data)
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
    })
}
