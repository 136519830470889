import { sleep } from '../../services/utils'
import { Food } from '../../pages/admin/food/types'
import { list, add, update, scratch, getFoodForSaleService } from '../../services/http/admin/foodService'
import { getCategoryWithID } from '../../services/http/admin/categoryService'
import { getUnitWithID } from '../../services/http/admin/unitService'
import { Pagination } from './common'
import { Category } from '../../pages/admin/categories/types'
import { Unit } from '../../pages/admin/units/types'

const getCategory = (id: string) => {
  return getCategoryWithID(id)
    .then((response) => {
      return response as Category
    })
    .catch(() => {
      return {}
    })
}
const getUnit = (id: string) => {
  return getUnitWithID(id)
    .then((response) => {
      return response as Unit
    })
    .catch(() => {
      return {}
    })
}

// Simulate API calls
export type Sorting = {
  sortBy: keyof Food | undefined
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: boolean
  search: string
}

export const getFoodList = async (filters: Partial<Filters & Pagination & Sorting>) => {
  await sleep(1000)

  const foods = await list(filters).then((response) => {
    return response
  })

  if (!foods.list) {
    return {
      data: [],
      pagination: {
        page: foods.metadata.currentPage,
        size: foods.metadata.size,
        total: 0,
        totalPages: foods.metadata.totalPages,
      },
    }
  }

  let filtered = foods.list as Food[]
  filtered = await Promise.all(
    filtered.map(async (food) => {
      const category = await getCategory(food.categoryID)
      const unit = await getUnit(food.unitID)
      return {
        ...food,
        category: category as Category,
        unit: unit as Unit,
      }
    }),
  )

  return {
    data: filtered,
    pagination: {
      page: foods.metadata.currentPage,
      size: foods.metadata.size,
      total: filtered.length * foods.metadata.totalPages,
      totalPages: foods.metadata.totalPages,
    },
  }
}

export const getAllFood = async () => {
  await sleep(1000)

  const filters = {
    page: 1,
    size: 9999,
  }
  const foods = await getFoodForSaleService(filters).then((response) => {
    return response
  })

  if (!foods.list) {
    return {
      data: [],
    }
  }

  return {
    data: foods.list,
  }
}

export const addNewFood = async (fd: Food) => {
  await sleep(1000)

  fd.categoryID = fd.category.categoryID
  fd.unitID = fd.unit.unitMeasureID
  fd.foodPrice = Number.parseFloat(fd.foodPrice.toString())
  await add(fd).then((response) => {
    return response
  })
}

export const updateFood = async (fd: Food) => {
  await sleep(1000)

  fd.categoryID = fd.category.categoryID
  fd.unitID = fd.unit.unitMeasureID
  fd.foodPrice = Number.parseFloat(fd.foodPrice.toString())
  await update(fd.foodID, fd).then((response) => {
    return response
  })
}

export const removeFood = async (fd: Food) => {
  await sleep(1000)
  await scratch(fd.foodID).then((response) => {
    return response
  })
}
