import { sleep } from '../../services/utils'
import { Category } from '../../pages/admin/categories/types'
import { list, add, update, scratch } from '../../services/http/admin/categoryService'
import { Pagination } from './common'

// Simulate API calls
export type Sorting = {
  sortBy: keyof Category | undefined
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: boolean
  search: string
}

export const getCategories = async (filters: Partial<Filters & Pagination & Sorting>) => {
  await sleep(1000)

  const resp = await list(filters).then((response) => {
    return response
  })

  if (!resp.list) {
    return {
      data: [],
      pagination: {
        page: resp.metadata.currentPage,
        size: resp.metadata.size,
        total: 0,
        totalPages: resp.metadata.totalPages,
      },
    }
  }

  return {
    data: resp.list as Category[],
    pagination: {
      page: resp.metadata.currentPage,
      size: resp.metadata.size,
      total: resp.list.length * resp.metadata.totalPages,
      totalPages: resp.metadata.totalPages,
    },
  }
}

export const addCategory = async (cate: Category) => {
  await sleep(1000)
  await add(cate).then((response) => {
    return response
  })
}

export const updateCategory = async (cate: Category) => {
  await sleep(1000)
  await update(cate.categoryID, cate).then((response) => {
    return response
  })
}

export const removeCategory = async (cate: Category) => {
  await sleep(1000)
  await scratch(cate.categoryID).then((response) => {
    return response
  })
}
