import repository from './repository'
import qs from 'qs'

export default {
  get(endpoint: string): Promise<any> {
    return repository.get(endpoint)
  },
  getWithParams(endpoint: string, params: any): Promise<any> {
    return repository.get(endpoint, {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
}
