import { defineStore } from 'pinia'
import { OrderItem, SaleTable } from '../pages/sales/types'
import { DinnerTable } from '../pages/admin/tables/types'
import { Food } from '../pages/admin/food/types'
import { Category } from '../pages/admin/categories/types'
import { Area } from '../pages/admin/areas/types'
import { getSaledTables } from '../data/sales/foods'
import { getAllFood } from '../data/pages/foods'
import { getCategories } from '../data/pages/categories'
import { getAllTable } from '../data/pages/tables'
import {
  addOrderDetailService,
  deleteOrderDetailService,
  newOrderService,
  newSaleTakeAwayService,
  paidInvoiceService,
  removeOrderService,
  updateOrderDetailService,
  updateOrderService,
} from '../services/http/sales/ordersService'
import { getAreas } from '../data/pages/areas'

export const useSalesStore = defineStore({
  id: 'salesHSQ',
  state: () => ({
    // config settings
    areas: [] as Area[],
    settingTables: [] as DinnerTable[],
    foods: [] as Food[],
    categories: [] as Category[],

    // sales data
    saleTables: [] as SaleTable[],
    activeOrderID: '',
    loading: false,
  }),
  getters: {
    currentSaleTable: (state): SaleTable | undefined => state.saleTables.find((tb) => tb.id === state.activeOrderID),
    allSaleTables: (state) => state.saleTables,
  },
  actions: {
    async load() {
      this.loading = true

      await getAreas({ page: 1, size: 9999 }).then((response) => {
        this.areas = response.data
      })

      await getAllTable().then((response) => {
        this.settingTables = response.data
      })

      await getCategories({ page: 1, size: 9999 }).then((response) => {
        this.categories = []
        this.categories.push({
          categoryID: 'all',
          categoryName: 'Tất cả',
          code: '',
          notes: '',
        })
        this.categories.push(...response.data)
      })

      await getAllFood().then((response) => {
        this.foods = response.data
      })

      await getSaledTables({ page: 1, size: 9999 }).then((response) => {
        this.saleTables = response.data
        this.activeOrderID = this.saleTables.length > 0 ? this.saleTables[0].id : ''
      })
      this.loading = false
    },
    checkSaleTableSelected() {
      return this.saleTables.findIndex((tb) => tb.id === this.activeOrderID) !== -1
    },
    checkSaleTableOrdered() {
      if (!this.checkSaleTableSelected()) return false
      if (this.currentSaleTable?.orders === null || this.currentSaleTable?.orders === undefined) return false
      return this.currentSaleTable?.orders.length !== 0
    },
    getSaleTable(id: string) {
      return this.saleTables.find((tb) => tb.id === id)
    },
    addTable(orderID: string, tableID: string, tableName: string) {
      this.saleTables.push({
        id: orderID,
        tableID: tableID,
        tableName: tableName,
        persons: 0,
        orders: [],
        printTime: '',
        printCount: 1,
        invoiceFee: {
          discount: 0,
          tax: 0,
          serviceFee: 0,
        },
      } as unknown as SaleTable)

      // update active table
      if (this.activeOrderID !== orderID) {
        if (this.saleTables.length > 0) {
          this.activeOrderID = orderID
        }
      }
      return this.saleTables
    },
    addSaleTable(tbID: string, persons: number) {
      newOrderService({
        tableID: tbID,
        tableName: this.settingTables.find((tb) => tb.tableID === tbID)?.tableName,
        persons: persons,
      }).then((response) => {
        this.addTable(response.orderID, response.tableID, response.tableName)
      })
    },
    addSaleTakeAWay() {
      newSaleTakeAwayService({}).then((response) => {
        this.addTable(response.orderID, response.tableID, response.tableName)
      })
    },
    removeSaleTable(id: string) {
      this.saleTables.splice(
        this.saleTables.findIndex((tb) => tb.id === id),
        1,
      )
      this.activeOrderID = this.saleTables.length > 0 ? this.saleTables[0].id : ''
      return this.saleTables
    },
    removeOrder(id: string) {
      removeOrderService(id).then(() => {
        console.log('remove invoice success')
        this.removeSaleTable(id)
      })
    },
    addOrder(food: Food) {
      if (!this.checkSaleTableSelected()) return
      if (this.currentSaleTable?.orders === null || this.currentSaleTable?.orders === undefined) {
        this.currentSaleTable!.orders = [] as OrderItem[]
      }

      const exitFood = this.currentSaleTable?.orders.findIndex((ff) => ff.foodID === food.foodID)
      if (exitFood !== -1) {
        this.currentSaleTable?.orders.forEach((ff) => {
          if (ff.foodID === food.foodID) {
            ff.foodCount++
            console.log('update order detail', ff)
            updateOrderDetailService(this.currentSaleTable!.id, ff.orderDetailID, {
              foodID: food.foodID,
              foodName: food.foodName,
              foodCount: ff.foodCount,
              foodPrice: food.foodPrice.toString(), // Convert number to string
              foodNote: food.notes,
            }).then((response) => {
              console.log('update order detail success', response)
            })
          }
        })
      } else {
        addOrderDetailService(this.currentSaleTable!.id, {
          foodID: food.foodID,
          foodCount: 1,
          foodName: food.foodName,
          foodPrice: food.foodPrice.toString(), // Convert number to string
          foodNote: food.notes,
        }).then((response) => {
          const detail = response
          this.currentSaleTable?.orders.push({
            orderDetailID: detail.orderDetailID,
            foodID: food.foodID,
            foodName: food.foodName,
            foodCount: 1,
            imgURL: food.imgURL,
            foodVAT: 0,
            foodPrice: food.foodPrice.toString(), // Convert number to string
            unitName: food.unit?.unitMeasureName,
            foodNote: detail.foodNote,
          })
        })
      }
      return this.saleTables
    },
    removeOrderDetail(orderDetail: OrderItem) {
      deleteOrderDetailService(this.currentSaleTable!.id, orderDetail.orderDetailID).then(() => {
        this.currentSaleTable?.orders.splice(
          this.currentSaleTable?.orders.findIndex((ff) => ff.orderDetailID === orderDetail.orderDetailID),
          1,
        )
      })

      return this.saleTables
    },
    updateInvoiceNote() {
      updateOrderService(this.currentSaleTable!.id, this.currentSaleTable).then((response) => {
        console.log('update invoice note success', response)
      })
    },
    updateBillPrintCount() {
      this.saleTables
        .filter((table) => table.id === this.activeOrderID)
        .forEach((item) => {
          item.printCount++
          item.printTime = new Date().toISOString()
        })

      updateOrderService(this.currentSaleTable!.id, this.currentSaleTable).then((response) => {
        console.log('update print bill status success', response)
      })
    },
    updateOrderDetails(foodID: string) {
      const exitFood = this.currentSaleTable?.orders.findIndex((ff) => ff.foodID === foodID)
      if (exitFood !== -1) {
        this.currentSaleTable?.orders.forEach((ff) => {
          if (ff.foodID === foodID) {
            updateOrderDetailService(this.currentSaleTable!.id, ff.orderDetailID, ff).then(() => {
              console.log('update order detail success')
            })
          }
        })
      }
    },
    paidInvoice(id: string, pAmount: number, pMethod: string, bTotal: number) {
      let activeTableID = this.activeOrderID
      if (id !== '' && id !== undefined) {
        activeTableID = id
      }

      this.saleTables
        .filter((table) => table.id === activeTableID)
        .forEach((item) => {
          paidInvoiceService(item.id, { paidAmount: pAmount, paidMethod: pMethod, billTotal: bTotal }).then(() => {
            console.log('paid invoice success')
            this.removeSaleTable(item.id)
          })
        })
    },
  },
})
