import { sleep } from '../../services/utils'
import { Area } from '../../pages/admin/areas/types'
import { list, add, update, scratch } from '../../services/http/admin/areaService'
import { Pagination } from './common'

// Simulate API calls
export type Sorting = {
  sortBy: keyof Area | undefined
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: boolean
  search: string
}

export const getAreas = async (filters: Partial<Filters & Pagination & Sorting>) => {
  await sleep(1000)

  const areas = await list(filters).then((response) => {
    return response
  })

  if (!areas.list) {
    return {
      data: [],
      pagination: {
        page: areas.metadata.currentPage,
        size: areas.metadata.size,
        total: 0,
        totalPages: areas.metadata.totalPages,
      },
    }
  }

  return {
    data: areas.list as Area[],
    pagination: {
      page: areas.metadata.currentPage,
      size: areas.metadata.size,
      total: areas.list.length * areas.metadata.totalPages,
      totalPages: areas.metadata.totalPages,
    },
  }
}

export const addArea = async (area: Area) => {
  await sleep(1000)
  await add(area).then((response) => {
    return response
  })
}

export const updateArea = async (area: Area) => {
  await sleep(1000)
  await update(area.areaID, area).then((response) => {
    return response
  })
}

export const removeArea = async (area: Area) => {
  await sleep(1000)
  await scratch(area.areaID).then((response) => {
    return response
  })
}
