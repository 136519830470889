import repository from './repository'

export default {
  scratch(endpoint: string): Promise<any> {
    return repository.delete(endpoint)
  },
  scratchWithData(endpoint: string, data: any): Promise<any> {
    return repository.delete(endpoint, data)
  },
}
