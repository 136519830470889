import { useUserStore } from '../../stores/user-store'

export default {
  isAuthenticated(): boolean {
    return useUserStore().isAuthenticated()
  },
  setCRSF(token: string): void {
    if (token) {
      localStorage.setItem('myCRSF', token)
    }
  },
  getCRSF(): string | null {
    return localStorage.getItem('authToken')
  },
  setToken(token: string): void {
    if (token) {
      localStorage.setItem('authToken', token)
    }
  },
  getToken(): string | null {
    return localStorage.getItem('authToken')
  },
  setUserInfo(data: any): void {
    localStorage.setItem('userInfo', JSON.stringify(data))
    useUserStore().setUserInfo()
  },
  getUserInfo(): any {
    return localStorage.getItem('userInfo')
  },
  clearToken(): void {
    localStorage.removeItem('authToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('myCRSF')
    useUserStore().clearUserInfo()
  },
}
